<template>
  <v-main v-if="lvl == 1">
    <v-container fluid class="text-right">
      <v-dialog v-model="dialog" max-width="400px">
        <template v-slot:activator="{ on }">
          <v-btn class="mx-3 ma-0" color="white" fab v-on="on" elevation=0><v-icon color="#ab1d66" x-large>add</v-icon></v-btn>
        </template>
        <v-card>
          <v-toolbar flat dense color="#535a69">
            <span class="font-weight-bold white--text">공지 추가</span>
            <v-spacer />
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
              <v-text-field v-model="editedItem.title" class="left-input" label="제목"></v-text-field>
              </v-col>
              <v-col cols="12">
              <v-textarea
                v-model="editedItem.content"
                solo="solo"
                background-color="amber lighten-4"
                color="orange orange-darken-4"
                label="내용"
                rows="12"
              ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" class="white--text" small @click="close">취소</v-btn>
            <v-btn color="blue darken-1" class="white--text" small @click="save">저장</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card class="pa-3 ma-2 text-left" outlined>
        <!-- <v-text-field v-model="messaging" class="left-input" label="알림 메시지"></v-text-field>
        <v-btn text icon @click="send_messaging()">보내기</v-btn> -->
      </v-card>
      <v-card class="pa-3 ma-2 text-left" outlined>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">공지</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                공지관리 관리자 화면
              </div>
            </div>
          </v-col>
          <v-col cols="9" >

            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col cols="12">
                  <v-row class="align-center justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              v-else
              :headers="headers"
              :items="notice_list"
              item-key="_id"
              show-expand
              class="elevation-2"
              :mobile-breakpoint=NaN
              no-data-text="목록이 없습니다."
            >
              <template v-slot:item.post_status="{ item }">
                <v-col cols="12" sm="3">
                  <v-btn text icon :color="item.post_status == true ? 'indigo' : 'grey'" @click="change_post_status(item)">
                    <v-icon>mdi-star</v-icon>
                  </v-btn>
                </v-col>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">
                  <v-textarea readonly :value="item.content"></v-textarea>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import { mapGetters } from 'vuex'

import api_conf from '../../config/config.json'

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      messaging: "",
      loading: true,
      dialog: false,
      notice_list: [],
      expanded: [],
      singleExpand: false,
      headers: [
        { text: '게시 상태', value: 'post_status' },
        { text: '제목', value: 'title' },
        { text: '날짜', value: 'date' },
        { text: '', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      editedIndex: -1,
      editedItem: {
        title:'',
        content:'',
        post_status: false
      },
      defaultItem: {
        title:'',
        content:'',
        post_status: false
      },
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",

    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    if ( firebase.auth().currentUser ) {
      this.arrange()

      this.loading = false;
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    async arrange() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const notice = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/notice/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if (notice.data.length>0) {
        this.notice_list = notice.data
      }
    },
    async change_post_status (item) {
      if ( item.post_status == false ) {
        item.post_status = true
      } else {
        item.post_status = false
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/notice/', {
        email: this.user.data.email,
        info: item,
      }, {
        headers: {'id_token': id_token},
      });
    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.notice_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save: async function () {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const result = await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/notice/', {
        email: this.user.data.email,
        info: this.editedItem,
      }, {
        headers: {'id_token': id_token},
      });

      if (this.editedIndex > -1) {
        Object.assign(this.notice_list[this.editedIndex], this.editedItem)
      } else {
        this.editedItem._id = result.data.rd._id;
        this.editedItem.date = this.$moment().format("YYYY-MM-DD");
        this.notice_list.push(this.editedItem);
      }

      this.close()
    },
    deleteItem: async function (item) {
      const index = this.notice_list.indexOf(item)

      const del_id = this.notice_list[index]._id;

      confirm('삭제하시겠습니까?') && this.notice_list.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/notice/', {
          data: {
            email: this.user.data.email,
            notice_id: del_id,
          },
          headers: {'id_token': id_token},
      });
    },
  }
}
</script>

<style scoped>


.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}

</style>
